<template>
  <div>
    <a-tag v-if="value" color="#108ee9">Aktif</a-tag>
    <a-tag v-else color="grey">Tidak Aktif</a-tag>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: [String, Object, Boolean], default: null },
  },
}
</script>

<style></style>
