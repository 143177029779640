<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-category class="ml-2" v-model:value="category" v-model:category="category" />
        <filter-subcategory
          class="ml-2 mt-2"
          v-model:value="subcategory"
          v-model:category="category"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-brand class="ml-2 mt-2" v-model:value="brand" v-model:brand="brand" />
        <filter-product class="ml-2 mt-2" v-model:value="product" v-model:brand="brand" />
        <a-input-search class="m-2" v-model:value="q" placeholder="Cari ..." style="width: 300px" />
        <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button title="Download Excel" type="primary" :loading="isDownloading" @click="fetchXlsx">
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive ">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #customTitle>
          <span>
            Status
          </span>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #status="{ text, record }">
          <a-tooltip>
            <a href="javascript: void(0);" @click="status(record.id)">
              <a-tag v-if="text" color="#108ee9">Aktif</a-tag>
              <a-tag v-else color="grey">Tidak Aktif</a-tag>
            </a>
          </a-tooltip>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Produk">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                v-has-access="['products.show']"
                @click="lihatProduk(record.id)"
              >
                <small>
                  <i class="fe fe-eye" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Edit Produk">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                v-has-access="['products.update']"
                @click="editProducts(record.id)"
              >
                <small>
                  <i class="fe fe-edit" />
                </small>
              </a>
            </a-tooltip>
            <a-tooltip title="Hapus Produk">
              <a
                href="javascript: void(0);"
                class="btn btn-sm btn-light"
                v-has-access="['products.destroy']"
                @click="postDelete(record.id)"
              >
                <small>
                  <i class="fe fe-trash" />
                </small>
              </a>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>

    <a-modal
      v-model:visible="modal1Visible"
      :title="formState.title_state"
      @ok="modal1Visible = false"
      width="678px"
      :destroy-on-close="true"
      :mask-closable="false"
      ref="modalRef"
    >
      <pre v-if="!true">{{ formState }}</pre>
      <template #footer>
        <a-button
          :loading="isPosting"
          key="submit"
          type="primary"
          @click="submit"
          :hidden="formState.readOnly"
          >Simpan</a-button
        >
      </template>
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :wrapper-col="{ span: 14 }"
        class="myform"
      >
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Kode SAP"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
              required
              has-feedback
              name="code"
            >
              <a-input
                placeholder="Kode SAP"
                width="100%"
                v-model:value="formState.code"
                :disabled="formState.readOnly"
                required
              />
            </a-form-item>
          </a-col>
        </a-row>

        <!-- kode referensi -->
        <a-row class="form-row mb-2">
          <a-col :sm="{ span: 24, offset: 7 }">
            <a-form class="myform-vertical" layout="vertical">
              <template v-for="(item, index) in formState.codes.filter(i => !i.is_deleted)" :key="index">
                <a-row class="form-row">
                  <a-input
                    size="small"
                    type="hidden"
                    v-model:value="item.id"
                    :disabled="formState.readOnly"/>

                  <!-- code -->
                  <a-col :sm="6">
                    <a-form-item
                      label="Kode Referensi Produk :"
                      label-align="left"
                      :name="['reference', index, 'code']"
                      has-feedback
                      :validate-status="(state.errors.codes && state.errors.codes[index]) ? 'error' : ''"
                      :help="(state.errors.codes && state.errors.codes[index]) ? state.errors.codes[index].code : ''">
                      <a-input
                        v-model:value="item.code"
                        :disabled="formState.readOnly"/>
                    </a-form-item>
                  </a-col>

                  <!-- name -->
                  <a-col :sm="8">
                    <a-form-item
                      label="Nama Produk :"
                      label-align="left"
                      :name="['reference', index, 'name']">
                      <a-input
                        v-model:value="item.name"
                        :disabled="formState.readOnly"/>
                    </a-form-item>
                  </a-col>

                  <a-col :sm="4" v-if="!formState.readOnly">
                    <a-form-item label=" " label-align="left">
                      <a-button
                        @click="removeReferenceRow(item)"
                        type="danger"><i class="fa fa-remove"></i>
                      </a-button>
                    </a-form-item>
                  </a-col>
                </a-row>
              </template>
            </a-form>
            <!-- add function -->
            <template v-if="!formState.readOnly">
              <a-form-item
                label-align="left"
                :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
                :colon="false">
                <a-button type="primary" @click="addReferenceCode">
                  <i class="fa fa-plus"></i> Tambah Kode Referensi
                </a-button>
              </a-form-item>
            </template>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Nama Produk"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
              required
              has-feedback
              name="name"
            >
              <a-input
                placeholder="Nama Produk"
                width="100%"
                v-model:value="formState.name"
                :disabled="formState.readOnly"
                required
              />
            </a-form-item>
          </a-col>
        </a-row>

        <!-- multi uom AAAAA-->
        <a-form-item
          label="Satuan"
          label-align="left"
          :label-col="{ sm: { span: 7 } }"
          :wrapper-col="{ sm: { span: 24 - 7 } }"
        >
          <a-col :sm="{ span: 24 }">
            <a-row class="myform-vertical" layout="vertical" v-if="formState.uoms">
              <template
                v-for="(item, index) in formState.uoms.filter(i => !i.is_deleted)"
                :key="index"
              >
                <a-input
                  size="small"
                  type="hidden"
                  v-model:value="item.id"
                  :disabled="formState.readOnly"
                />
                <a-row class="form-row" :gutter="[8, 8]">
                  <a-col :sm="5">
                    <a-form-item
                      label="Satuan"
                      label-align="left"
                      :wrapper-col="{ sm: { span: 24 } }"
                      :name="['uoms', index, 'uom']"
                      :rules="{
                        required: true,
                        message: 'Satuan tidak boleh kosong!',
                      }"
                      :help="
                        Array.isArray(item.errors)
                          ? item.errors.find(i => i.field === 'uom')
                            ? item.errors.find(i => i.field === 'uom').message
                            : undefined
                          : undefined
                      "
                      :validate-status="
                        Array.isArray(item.errors)
                          ? item.errors.find(i => i.field === 'uom') !== null
                            ? 'error'
                            : undefined
                          : undefined
                      "
                    >
                      <filter-satuan
                        :mode="null"
                        model-id="name"
                        v-model:value="item.uom"
                        placeholder="Pilih Satuan"
                        :disabled="formState.readOnly"
                        style="width: 100%"
                      ></filter-satuan>
                    </a-form-item>
                  </a-col>
                  <a-col>
                    <a-form-item
                      label="Konversi"
                      :wrapper-col="{ sm: { span: 20 } }"
                      label-align="left"
                    >
                      <a-input
                        type="number"
                        v-model:value="item.conversion"
                        :disabled="formState.readOnly"
                      />
                    </a-form-item>
                  </a-col>

                  <a-col>
                    <a-form-item label=" " label-align="left">
                      <a-button
                        @click="removeRow(item)"
                        size="small"
                        type="danger"
                        style="margin-top: 22px"
                        :wrapper-col="{ sm: { span: 24 } }"
                        :disabled="formState.readOnly"
                        ><i class="fa fa-remove"></i
                      ></a-button>
                    </a-form-item>
                  </a-col>
                </a-row>
              </template>
            </a-row>
            <!-- add function -->
            <template v-if="!formState.readOnly">
              <a-form-item
                label-align="left"
                :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
                :colon="false"
              >
                <a-button type="primary" @click="addUom"
                  ><i class="fa fa-plus"></i> Tambah Satuan</a-button
                >
              </a-form-item>
            </template>
          </a-col>
        </a-form-item>
        <!-- end multi uom -->

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Kategori Produk"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
            >
              <filter-category
                :mode="null"
                v-model:value="formState.category_id"
                style="width: 100%"
                placeholder="Pilih Kategori Produk"
                :disabled="formState.readOnly"
              ></filter-category>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Sub Kategori Produk"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
            >
              <filter-subcategory
                :mode="null"
                v-model:value="formState.subcategory_id"
                v-model:category="formState.category_id"
                style="width: 100%"
                placeholder="Pilih Sub Kategori Produk"
                :disabled="formState.readOnly"
              ></filter-subcategory>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Type"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
            >
              <filter-product-type
                :mode="null"
                v-model:value="formState.type"
                style="width: 100%"
                placeholder="Pilih Tipe"
                :disabled="formState.readOnly"
              >
              </filter-product-type>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Brand Produk"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
            >
              <filter-brand
                :mode="null"
                v-model:value="formState.brand_id"
                style="width: 100%"
                placeholder="Pilih Brand Produk"
                :disabled="formState.readOnly"
              >
              </filter-brand>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Suplier"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
            >
              <filter-supplier
                :mode="null"
                class="mr-2"
                style="width: 100%;"
                placeholder="Pilih Supplier"
                v-model:value="formState.supplier_id"
                :disabled="formState.readOnly"
              ></filter-supplier>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Harga Suplier"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
            >
              <a-input
                :disabled="formState.readOnly"
                placeholder="Harga Suplier"
                width="100%"
                v-model:value="formState.price"
                type="number"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Detail Produk"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
            >
              <a-textarea
                placeholder="Detail Produk"
                width="100%"
                v-model:value="formState.description"
                :disabled="formState.readOnly"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Foto Produk"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
            >
              <template v-if="!formState.readOnly">
                <a-input
                  required
                  v-if="!formState.image || formState.image === 'null'"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  @change="e => onFileChange(e, 'image')"
                ></a-input>
                <a-button
                  v-else
                  @click="
                    () => {
                      formState.image = null
                      imageBase = null
                    }
                  "
                  >Hapus Foto</a-button
                >
              </template>
              <img
                v-if="formState.image !== 'null'"
                style="display: block; max-width:150px;
                width: auto;
                height: auto;"
                :src="typeof formState.image === 'string' ? formState.image : imageBase"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <!-- <pre>{{ formState }}</pre> -->
    </a-modal>
    <!-- end of modal crud -->

    <a-modal
      v-model:visible="modal2Visible"
      :title="formState.title_state"
      @ok="modal2Visible = false"
    >
      <template #footer>
        <a-button
          :loading="isPosting"
          key="simpan"
          type="primary"
          @click="simpan"
          :hidden="formState.readOnly"
          >Simpan</a-button
        >
      </template>

      <a-form :wrapper-col="{ span: 14 }" class="myform">
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Pilih Status"
              label-align="left"
              :label-col="{ sm: { span: 7 } }"
              :wrapper-col="{ sm: { span: 24 - 7 } }"
            >
              <a-select v-model:value="formState.active" :disabled="formState.readOnly">
                <a-select-option :value="true">Aktif</a-select-option>
                <a-select-option :value="false">Tidak Aktif</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  nextTick,
  toRef,
  onMounted,
  reactive,
  watch,
  provide,
  toRefs,
  toRaw,
} from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterBrand from '@/components/filter/FilterBrand'
import FilterProduct from '@/components/filter/FilterProduct'
import FilterCategory from '@/components/filter/FilterCategory'
import FilterSubcategory from '@/components/filter/FilterSubCategory'
import { RenderActive } from '@/components'
// import rules from './rules'
import acolumns from './columns'
import qs from 'qs'
import { computed } from 'vue'
import { FilterSatuan, FilterSupplier, FilterProductType } from '@/components/filter'
import moment from 'moment'
import { serialize } from 'object-to-formdata'
import _ from 'lodash'

const initial = {
  id: null,
  code: null,
  name: null,
  description: null,
  image: null,
  brand_id: null,
  category_id: null,
  supplier_id: null,
  //todo: kurang api
  subcategory_id: null,
  type: null,
  uom_id: null,
  barcode: null,
  price: null,
  uoms: [],
  codes: [],
}

export default defineComponent({
  name: 'MFilter',
  components: {
    FilterBrand,
    FilterProduct,
    FilterCategory,
    FilterSubcategory,
    FilterSatuan,
    FilterSupplier,
    FilterProductType,
  },
  setup() {
    const modalRef = ref('')
    const modal1Visible = ref(false)
    const modal2Visible = ref(false)
    const modal3Visible = ref(false)
    const formState = ref([])
    const formStates = reactive({
      name: undefined,
      code: undefined,
    })

    const image = toRef(formState.value, 'image')
    const ifimage = ref(true)

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const data = ref([])
    const q = ref('')
    const columns = ref([])
    const isPosting = ref(false)

    columns.value = acolumns

    const searchText = ref('')
    const searchInput = ref(null)
    const product = ref([])
    const brand = ref([])
    const category = ref([])
    const type = ref([])
    const subcategory = ref([])

    const dataProduct = ref([])
    const dataBrand = ref([])
    const dataCategory = ref([])
    const dataType = ref([])
    const dataSubCategory = ref([])
    const value = ref([])
    const errorMessage = ref(null)

    const supplier_id = ref([])

    const formRef = ref('')

    const imageBase = ref('')
    const imageToBase64 = (file, to) => {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        to.value = reader.result
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    }

    const onFileChange = async (e, type) => {
      var files = e.target.files || e.dataTransfer.files
      console.log(files.length, type)
      if (!files.length) return
      var filesize = (files[0].size / 1024 / 1024).toFixed(4) // MB
      if (filesize > 2.0) {
        message.warning('Ukuran harus di bawah 2MB!', 5.0)
        if ('image' === type) {
          ktp.value = false
        }
        await nextTick()
        if ('image' === type) {
          ktp.value = true
        }
        return
      }

      formState.value.image = files[0]
      imageToBase64(files[0], imageBase)
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/products', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `master-produk_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }
    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        category: category.value,
        subcategory: subcategory.value,
        product: product.value,
        brand: brand.value,
        q: q.value,
        supplier_id: supplier_id.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/products', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const lihatProduk = id => {
      apiClient
        .get(`/api/products/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Lihat Produk'
          formState.value.readOnly = true
          apiClient
            .get(`/api/products/${id}`)
            .then(({ data }) => {
              formState.value.vendorCodes = Object.assign([], data.items)
            })
            .catch(error => console.error(error))
        })
        .catch(error => console.error(error))
    }
    const editProducts = id => {
      apiClient
        .get(`/api/products/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal1Visible.value = true
          formState.value.title_state = 'Edit Produk'
          formState.value.state = 'edit'
          formState.value.readOnly = false
          apiClient
            .get(`/api/products/${id}/codes`)
            .then(({ data }) => {
              formState.value = Object.assign([], data.items)
            })
            .catch(error => console.error(error))
        })
        .catch(error => console.error(error))
    }

    const status = id => {
      apiClient
        .get(`/api/products/${id}`)
        .then(response => {
          const data = response.data
          formState.value = data
          modal2Visible.value = true
          formState.value.title_state = 'Edit Status'
          formState.value.state = 'status'
          formState.value.readOnly = false
        })
        .catch(error => console.error(error))
    }

    const submit = () => {
      //  modalRef.value.validate()
      if (formState.value.state === 'edit') {
        Modal.confirm({
          title: 'Konfirmasi Edit Product',
          content: 'Apakah anda ingin melanjutkan Edit Product ?',
          onOk() {
            isPosting.value = true
            // const form_data = new FormData()
            // for (var key in formState.value) {
            //   if (key === 'active') {
            //     form_data.append(key, formState.value[key] ? 1 : 0)
            //   } else
            //     form_data.append(key, formState.value[key] === null ? '' : formState.value[key])
            // }
            const form_data = serialize(
              {
                ...formState.value,
              },
              { indices: true, booleansAsIntegers: true },
            )
            // TODO: validasi
            apiClient
              .post('/api/products/' + formState.value.id + '?_method=PUT', form_data)
              .then(({ data }) => {
                modal1Visible.value = false
                isPosting.value = false
                imageBase.value = null
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(({ response: { data, status } }) => {
                if (data) {
                    state.errors = _(data).groupBy(error => error.field.match(/^[^\[]+/)[0])
                    .mapValues(groupedErrors => groupedErrors.map(error => {
                      const indexMatch = error.field.match(/\[(\d+)\]/);
                      const keyMatch = error.field.match(/\[([^\[]+)\]$/);
                      if (indexMatch && keyMatch) {
                        return {
                          [keyMatch[1]]: error.message,
                        };
                      }
                      return error.message;
                    }))
                    .value()
                }

                if (!state.errors.codes) {
                  message.error('Gagal menyimpan, silahkan isi baris sesuai nama')
                }
              })
              .finally(e => {
                isPosting.value = false
              })
          },
          onCancel() {},
        })
      }
    }

    const postDelete = id => {
      Modal.confirm({
        title: 'Konfirmasi hapus Products',
        content: 'Apakah anda ingin hapus Products ?',
        onOk() {
          apiClient
            .delete(`/api/products/${id}`)
            .then(response => {
              const data = response.data
              formState.value = data
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(e => message.error('Gagal menghapus!'))
        },
        onCancel() {},
      })
    }

    const simpan = () => {
      if (formState.value.state === 'status') {
        Modal.confirm({
          title: 'Konfirmasi Edit Status',
          content: 'Apakah anda ingin melanjutkan merubah status ?',

          onOk() {
            apiClient
              .post('/api/products/' + formState.value.id + '?_method=PUT', formState.value)
              .then(({ data }) => {
                modal1Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal menyimpan, silahkan pilih status'))
              .finally(e => {
                isPosting.value = false
              })
          },
          onCancel() {},
        })
      }
    }
    provide('formState', formState)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
      reference: {
        id: null,
        code: null,
        name: null,
      },
      errors: {},
    })

    const setModal1Visible = visible => {
      modal1Visible.value = visible
      if (!visible) {
        imageBase.value = null
      }
    }

    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
    }

    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const search = () => {
      fetchData()
    }

    onMounted(() => {
      fetchData()
    })

    const validate = () => {
      formRef.value
        .validate()
        .then(() => {
          console.log('values', formState, toRaw(formState))
        })
        .catch(error => {
          console.log('error', error)
        })
    }

    const rules = {
      name: {
        required: true,
        message: 'Masukan Nama Produk',
      },
      code: {
        required: true,
        message: 'Masukan Kode',
      },
    }
    // UOM Logic
    const uom = ref({
      id: null,
      uom: null,
      conversion: null,
      produk_id: null,
    })

    const addUom = () => {
      if (formState.value.uoms.filter(v => v.uom === null).length > 0) {
        message.warning('Pastikan satuan terisi!')
        return
      }
      const m = Object.assign({}, uom.value)
      formState.value.uoms.push(m)
    }

    const removeRow = item => {
      const index = formState.value.uoms.indexOf(item)
      // if (item.produk_id === null)
      formState.value.uoms.splice(index, 1)
      // else {
      // item.is_deleted = true
      // }
    }

    const uomState = { addUom, removeRow }

    /// vendor code
    const addReferenceCode = () => {
      if (formState.value.codes.filter(v => v.code === null).length > 0) {
        message.warning('Pastikan kode referensi terisi!')
        return
      }
      const m = Object.assign({}, state.reference)
      formState.value.codes.push(m)
    }

    const removeReferenceRow = item => {
      const index = formState.value.codes.indexOf(item)
      if (item.id === null) formState.value.codes.splice(index, 1)
      else {
        item.is_deleted = true
      }
    }

    return {
      modal1Visible,
      modal2Visible,
      modal3Visible,
      setModal1Visible,
      data,
      columns,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      searchText,
      searchInput,
      dataProduct,
      dataBrand,
      dataCategory,
      dataType,
      dataSubCategory,
      formState,
      product,
      brand,
      category,
      type,
      subcategory,
      fetchXlsx,
      search,
      onSelectChange,
      q,
      startRow,
      state,
      ...toRefs(state, formState),
      fetchData,
      editProducts,
      lihatProduk,
      isPosting,
      submit,
      modalRef,
      postDelete,
      handleTableChange,
      supplier_id,
      status,
      simpan,
      rules,
      formRef,
      validate,
      formStates,
      value,
      errorMessage,
      onFileChange,
      imageToBase64,
      imageBase,
      image,
      ifimage,
      ...uomState,
      addReferenceCode,
      removeReferenceRow,
    }
  },
})
</script>
<style scoped>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector),
.myform :deep(.ant-radio-disabled + span) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
  border: 0;
}
/* .ant-select-selection-placeholder */
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
.myform :deep(.ant-select-disabled .ant-select-selection-placeholder),
.myform :deep(input.ant-input.ant-input-disabled::placeholder) {
  display: none;
  color: transparent;
}
.myform-vertical :deep(.ant-form-item-label > label) {
  font-size: 12px;
  margin: 0;
}
.myform-vertical :deep(.ant-form-item-label) {
  line-height: 1;
  padding: 0;
}
</style>
<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
