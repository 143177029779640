const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Kode Produk',
    dataIndex: 'code',
    sorter: (a, b) => a.id - b.id,
     title_xls: 'Kode Produk',
  },
  {
    title: 'Nama Produk',
    dataIndex: 'name',
     title_xls: 'Nama Produk',
  },
  {
    title: 'Kategori',
    dataIndex: 'category',
     title_xls: 'Kategori',
  },
  {
    title: 'Sub Kategori',
    dataIndex: 'subcategory',
     title_xls: 'Sub Kategori',
  },
  {
    title: 'Brand Produk',
    dataIndex: 'brand_name',
     title_xls: 'Brand Produk',
  },

  {
    align: 'center',
    export:false,
    slots: {
      customRender: 'status' ,
      title: 'customTitle',
    },
     dataIndex: 'active',
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
export default acolumns
